.c3-tooltip-container {
  padding: 4px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #222222;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-transform: uppercase;
}

.c3-tooltip {
  box-shadow: none;
}

.c3-tooltip tr {
  border: 0;
}

.c3-tooltip th {
  background: #ffffff;
  color: #222222;
  padding: 6px 12px 4px;
  border-bottom: 1px solid #546e7a
}

.c3-tooltip tr:nth-of-type(2) td {
  padding-top: 8px;
}

.c3-tooltip td {
  border: 0;
  padding: 4px;
  font-size: 12px;
}

.c3-tooltip td:last-of-type {
  font-weight: bold;
}

.c3-axis .tick {
  fill: #546e7a;
}